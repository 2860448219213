import React from "react";
import {Button, Icon, Input, Message} from "semantic-ui-react";
import {NavLink} from "react-router-dom";
const Login = (props) => {

    return (
        <form id={'login'} className={"form auth section"} onSubmit={(event) => {
            event.preventDefault();
            props.singIn(props.login);
        }}>
            <h2>Sign In</h2>
            <Input iconPosition='left' placeholder='E-mail' value={props.login.email} required type='email' maxLength='32' onChange={(e) => {
                props.setLoginData('email', e.target.value);
            }}>
                <Icon name='at' />
                <input />
            </Input>

            <Input iconPosition='left' placeholder='Password' value={props.login.password} required type='password' name='password' minLength='6' maxLength='32' onChange={(e) => {
                props.setLoginData('password', e.target.value);
            }}>
                <Icon name='unlock alternate' />
                <input />
            </Input>
            <Message color={props.login.alert_color} hidden={(!props.login.alert)}>{props.login.alert}</Message>
            <Button loading={props.login.loading} disabled={props.login.loading} color='black'>Login</Button>
            <p>
                <NavLink to={"/restore"}>Forgot password?</NavLink> /
                Don't have an account? <b><NavLink to={"/registration"}>Sing Up</NavLink></b>
            </p>
        </form>
    )
};

export default Login;