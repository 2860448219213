import {AuthApi} from "../Api/Auth";
import {NotificationApi} from "../Api/Notification";
import {getCookie} from "../helpers";
import {setTraderData} from "./traders-reducer";
import {UserApi} from "../Api/User";
import {setAlerts} from "./page-reducer";
import moment from "moment/moment";
import React from "react";

const SET_USER_DATA = "SET_USER_DATA";
const SET_USER_ACTIONS_DATA = "SET_USER_ACTIONS_DATA";
const SET_ORDER_ACTIONS = "SET_ORDER_ACTIONS";
const SET_ORDER_DIRECTION = "SET_ORDER_DIRECTION";
const SET_ORDER_SYMBOLS = "SET_ORDER_SYMBOLS";

let initialState = {
    authorized: false,
    name: "",
    email: "",
    token: "",
    password: "",
    password2: "",
    subscription_expiration_date: "",
    traders: [],
    notification_settings: {
        actions: {
            opened: true,
            closed: true,
            decreased: true,
            increased: true,
            liquidated: true,
        },
        symbols: [],
        traders: [],
        directions: {
            long: true,
            short: true,
        }
    },
    watchlist_limit: 0,
    status: "",
    actions: {
        loading: true,
        account_loading: false,
    },
    telegram: true,
    is_subscribed: true
};

function usersReducer(state = initialState, action) {
    switch (action.type) {

        case SET_USER_DATA:
            return {...state, [action.name]: action.value};

        case SET_USER_ACTIONS_DATA:
            return {...state, actions: {...state.actions, [action.name]: action.value }};

        case SET_ORDER_SYMBOLS:
            return {...state, notification_settings: {...state.notification_settings, symbols: action.symbols }};

        case SET_ORDER_ACTIONS:
            return {...state, notification_settings:
                    {...state.notification_settings, actions:
                            { ...state.notification_settings.actions, [action.name]: action.value } }};

        case SET_ORDER_DIRECTION:
            return {...state, notification_settings:
                    {...state.notification_settings, directions:
                            { ...state.notification_settings.directions, [action.name]: action.value } }};

        default:
            return state;
    }
}

export function setOrderSymbols(value) {
    let symbols = []
    value.forEach((item)=>{
        symbols.push(item.symbol)
    })
    return {type: SET_ORDER_SYMBOLS, symbols};
}
export function setUserData(name, value) {
    return {type: SET_USER_DATA, name, value};
}

export function setUserActionsData(name, value) {
    return {type: SET_USER_ACTIONS_DATA, name, value};
}

export function setOrderActions(name, value) {
    return {type: SET_ORDER_ACTIONS, name, value};
}

export function setOrderDirection(name, value) {
    return {type: SET_ORDER_DIRECTION, name, value};
}

export const getUserData = () => {
    return (dispatch) => {

        let token = getCookie('token');

        if(!token){
            dispatch(setUserData("authorized", false));
            dispatch(setUserData("token", ""));
            dispatch(setUserData("email", ""));
            dispatch(setUserActionsData("loading", false));
            return false;
        }

        AuthApi.user_data(token).then(response => {
            if(response && response.values && response.values.success === true){
                dispatch(setUserData("authorized", true));
                dispatch(setUserData("token", response.values.token));
                dispatch(setUserData("email", response.values.email));
                dispatch(setUserData("name", response.values.name));
                dispatch(setUserData("subscription_expiration_date", response.values.subscription_expiration_date));
                dispatch(setUserData("traders", response.values.traders));
                dispatch(setUserData("notification_settings", response.values.notification_settings));
                dispatch(setUserData("watchlist_limit", response.values.watchlist_limit));
                dispatch(setUserData("status", response.values.status));
                dispatch(setUserData("telegram", response.values.telegram));

                let expiration = moment(response.values.subscription_expiration_date).format();
                let currentTime = moment().format();

                if(expiration > currentTime){
                    dispatch(setUserData("is_subscribed", true));
                } else {
                    dispatch(setUserData("is_subscribed", false));
                }


            } else {
                dispatch(setUserData("authorized", false));
                dispatch(setUserData("token", ""));
                dispatch(setUserData("email", ""));
            }
            dispatch(setUserActionsData("loading", false));
        });
    };
};

export const traderNotifyOn = (id, index) => {
    return (dispatch) => {
        dispatch(setTraderData(index, "loading", true));
        NotificationApi.traderOn(id).then(response => {
            setTimeout(function() {
                if(response && response.values && response.values.success === true){
                    dispatch(getUserData());
                }
                dispatch(setTraderData(index, "loading", false));
            }, 300);
        });
    };
};

export const traderNotifyOff = (id, index) => {
    return (dispatch) => {
        dispatch(setTraderData(index, "loading", true));
        NotificationApi.traderOff(id).then(response => {
            setTimeout(function() {
                if(response && response.values && response.values.success === true){
                    dispatch(getUserData());
                }
                dispatch(setTraderData(index, "loading", false));
            }, 300);
        });
    };
};

export const notificationUpdate = () => {
    return (dispatch, getState) => {
        const state = getState();
        NotificationApi.update(state.Users.notification_settings).then(response => {
            setTimeout(function() {
                if(response && response.values && response.values.success === true){
                    //dispatch(getUserData());
                }
            }, 100);
        });
    };
};

export const updateUserData = () => {
    return (dispatch, getState) => {
        dispatch(setUserActionsData("account_loading", true));
        const state = getState();
        UserApi.updateUser(state.Users.name,state.Users.password,state.Users.password2).then(response => {

            if(response && response.values && response.values.success === true){
                dispatch(setAlerts("color", "green"));
            } else {
                dispatch(setAlerts("color", "red"));
            }

            dispatch(setUserData("password", ""));
            dispatch(setUserData("password2", ""));
            setTimeout(function() {
                dispatch(setAlerts("message", response.values.message));
                dispatch(setUserActionsData("account_loading", false));
            }, 300);
        });
    };
};

export default usersReducer;