import instance from './Api';

export const OptionsApi = {

    currencies(){
        return instance.get(`options/currencies`)
            .then(response => {
                return response;
            }).catch(function (error) {
                return error;
            });
    }

};