import instance from './Api';
import {getCookie} from "../helpers";

export const UserApi = {

    updateUser(name, password, password2){

        let config = {
            headers:{
                "auth-token": getCookie("token")
            }
        }

        let data = {}
        data.name = name;

        if(password && password2){
            data.password = password;
            data.password2 = password2;
        }

        return instance.post(`user/update`, data, config)
            .then(response => {
                return response.data;
            }).catch(function (error) {
                return error.response.data;
            });
    }

};