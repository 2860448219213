import React, { Component } from 'react'
import { Accordion, Icon } from 'semantic-ui-react'
import {NavLink} from "react-router-dom";

export default class FAQ extends Component {
    state = { activeIndex: 0 }

    handleClick = (e, titleProps) => {
        const { index } = titleProps
        const { activeIndex } = this.state
        const newIndex = activeIndex === index ? -1 : index

        this.setState({ activeIndex: newIndex })
    }

    render() {
        const { activeIndex } = this.state

        return (
            <div id={"faq"}>

                <h2>Order Watcher FAQ</h2>

                <Accordion fluid styled>
                    <Accordion.Title
                        active={activeIndex === 0}
                        index={0}
                        onClick={this.handleClick}
                    >
                        <Icon name='dropdown' />
                        How to start tracking the traders positions?
                    </Accordion.Title>
                    <Accordion.Content active={activeIndex === 0}>



                        <p>1. <NavLink to={"/registration"}><b>Register</b></NavLink> an account and verify email</p>
                        <p>2. Choose and follow top traders from <NavLink to={"/leaderboard"}><b>our leaderboard</b></NavLink></p>
                        <p>3. Subscribe to the <NavLink target={"_blank"} to={"https://t.me/OrderWatcherRobot"}><b>our Telegram Bot</b></NavLink> to receive notifications of position changes</p>
                    </Accordion.Content>

                    <Accordion.Title
                        active={activeIndex === 1}
                        index={1}
                        onClick={this.handleClick}
                    >
                        <Icon name='dropdown' />
                        Why do I need to subscribe to a telegram bot?
                    </Accordion.Title>
                    <Accordion.Content active={activeIndex === 1}>
                        <p>To receive real-time notifications, you need to have a telegram account and be subscribed to <NavLink target={"_blank"} to={"https://t.me/OrderWatcherRobot"}><b>our Telegram Bot</b></NavLink>.</p>
                        <p>We notify our users through the telegram bot, as this is the easiest, fastest and most reliable way.</p>
                    </Accordion.Content>

                    <Accordion.Title
                        active={activeIndex === 2}
                        index={2}
                        onClick={this.handleClick}
                    >
                        <Icon name='dropdown' />
                        How safe is it to use Order Watcher?
                    </Accordion.Title>
                    <Accordion.Content active={activeIndex === 2}>
                        <p>We do not collect any personal data, do not use credentials to your trading accounts and do not require access to your API.</p>
                        <p>All data processing takes place on our servers, you only receive notifications about the actions of traders.</p>
                        <p>Order Watcher is an analytics service. You fully assume all the risks of trading operations.</p>
                    </Accordion.Content>
                </Accordion>
            </div>
        )
    }
}