import React from "react";
import {usd, numb} from "../../../../helpers";
import {Button, Dimmer, Loader} from "semantic-ui-react";
import Select from "react-dropdown-select";

const Positions = (props) => {

    let positionsData = "";

    if(props.profile.opened_positions !== 0 && !props.profile.opened_positions){
        positionsData = <h3>Trader positions are not tracked. To start tracking positions, subscribe to a trader.</h3>;
    } else {
        positionsData = mapPositions(props);
    }

    return (
        <section id={'positions'} className={'section'}>
            <div className={'table'}>
                <div className={'header row'}>
                    <div className={'symbol'}>Symbol</div>
                    <div className={'size'}>Size</div>
                    <div className={'cost no-mob'}>Total Cost</div>
                    <div className={'entry-price'}>Entry Price</div>
                    <div className={'mark-price no-mob'}>Mark Price</div>
                    <div className={'pnl'}>PNL (ROE %)</div>
                    <div className={'profit'}>Profit/Loss</div>
                    <div className={'btn search'}>
                        <Select
                            className={'search'}
                            multi
                            placeholder="Search..."
                            valueField={'symbol'}
                            labelField={'symbol'}
                            searchBy={'symbol'}
                            values={props.filtered}
                            options={props.positions}
                            onChange={(values) => {
                                props.setPositions("filtered", values)
                            }}
                        />
                    </div>
                </div>
                {positionsData}
            </div>
        </section>
    )
};


function mapPositions(props) {

    if(props.loading){
        return (
            <Dimmer active inverted>
                <Loader inverted>Loading</Loader>
            </Dimmer>
        )
    }


    let data = props.positions
    if(!data[0]) return <h3>The trader did not open new positions. The data will be updated soon.</h3>;

    if(props.filtered[0]){
        data = props.filtered
    }

    return (
        data.map((value) => {
            return (
                <div className={'body row' + (value.pnl >= 0 ? " positive" : " negative")}>
                    <div className={'symbol'}><span className={'ticker'}>{value.symbol}</span><span className={'direction ' + value.direction.toLowerCase()}>{value.direction}</span><span className={'leverage'}>{value.leverage}x</span></div>
                    <div className={'size'}>{numb.format(value.amount)}</div>
                    <div className={'cost no-mob'}>{usd.format(value.total_cost)}</div>
                    <div className={'entry-price'}>{value.entryPrice}</div>
                    <div className={'mark-price no-mob'}>{value.markPrice}</div>
                    <div className={'pnl'}><span className={'sum'}>{usd.format(value.pnl)}</span><span className={'roe'}>{numb.format(value.roe*100)}%</span></div>
                    <div className={'profit'}>{usd.format(value.profit)}</div>
                    <div className={'btn'}><Button color='teal' onClick={()=>{
                        props.getActions(value.id)
                    }}>Actions</Button></div>
                </div>
            );
        })
    )
}

export default Positions;