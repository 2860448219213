import React, { Component } from 'react';
import {connect} from "react-redux";
import Index from "./Public/Index/IndexContainer";
import {Route, Routes, Navigate} from "react-router-dom";
import Login from "./Auth/Login/LoginContainer";
import Restore from "./Auth/Restore/RestoreContainer";
import Registration from "./Auth/Registration/RegistrationContainer";
import Dashboard from "./Dashboard/DashboardContainer";
import Billing from "./Billing/BillingContainer";
import Account from "./Account/AccountContainer";
import TraderProfile from "./Dashboard/TraderProfile/TraderProfileContainer";
import Header from "./Header/HeaderContainer";
import Footer from "./Footer/FooterContainer";
import Confirmation from "./Auth/Confirmation/ConfirmationContainer";
import {getUserData} from "../redux/user-reducer";
import Leaderboard from "./Public/Leaderboard/LeaderboardContainer";

class PageRouter extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.props.getUserData();
    }

    render() {

        if(this.props.user.actions.loading){
            return <div></div>
        }

        return (
            <section id="app">
                <Header />
                <Routes>
                    <Route path="/" element={<Index />} />
                    <Route path="/leaderboard" element={<Leaderboard />} />
                    <Route exact path={'/login'} element={(this.props.user.authorized ?         <Navigate to="/watchlist" /> : <Login />)} />
                    <Route exact path={'/registration'} element={(this.props.user.authorized ?  <Navigate to="/watchlist" /> : <Registration />)} />
                    <Route exact path={'/restore'} element={(this.props.user.authorized ?       <Navigate to="/watchlist" /> : <Restore />)} />
                    <Route exact path={'/confirmation'} element={(this.props.user.authorized ?  <Navigate to="/watchlist" /> : <Confirmation />)} />

                    <Route exact path={'/watchlist'} element={(this.props.user.authorized ?     <Dashboard /> : <Navigate to="/login" />)} />
                    <Route exact path={'/billing'} element={(this.props.user.authorized ?       <Billing /> : <Navigate to="/login" />)} />
                    <Route exact path={'/account'} element={(this.props.user.authorized ?       <Account /> : <Navigate to="/login" />)} />
                    <Route exact path={'/trader/:id'} element={(this.props.user.authorized ?    <TraderProfile /> : <Navigate to="/login" />)} />
                </Routes>
                <Footer />
            </section>
        )
    }
}

const mapStateToProps = state => {
    return {
        user:state.Users
    };
};

export default connect(
    mapStateToProps, {
        getUserData
    })(PageRouter);