import React from 'react';
import ReactDOM from 'react-dom';
import 'semantic-ui-css/semantic.min.css'
import './style/main.css';
import './style/header.css';
import './style/forms.css';
import * as serviceWorker from './serviceWorker';
import {Provider} from "react-redux";
import store from "./redux/redux-store";
import PageRouter from "./components/PageRouter";
import {BrowserRouter} from "react-router-dom";


ReactDOM.render(
    <Provider store={store}>
        <BrowserRouter>
            <PageRouter store={store} />
        </BrowserRouter>
    </Provider>, document.getElementById('root')
);

serviceWorker.unregister();