import {AuthApi} from "../Api/Auth";
import {NotificationApi} from "../Api/Notification";
import {getCookie, removeCookie, setCookie} from "../helpers";
import {setTraderData} from "./traders-reducer";
import {getUserData, setUserActionsData, setUserData} from "./user-reducer";
import {setAlerts} from "./page-reducer";
const SET_REGISTRATION_DATA = "SET_REGISTRATION_DATA";
const SET_LOGIN_DATA = "SET_LOGIN_DATA";
const SET_RESTORE_DATA = "SET_RESTORE_DATA";
const SET_CONFIRMATION_DATA = "SET_CONFIRMATION_DATA";

let initialState = {
    registration:{
        alert: "",
        loading: false,
        alert_color: "green",
        name: "",
        email: "",
        password: "",
        password2: "",
    },
    login:{
        loading:false,
        alert: "",
        alert_color: "green",
        email: "",
        password: "",
    },
    restore:{
        loading:false,
        alert: "",
        alert_color: "green",
        email: "",
        password: "",
        password2: "",
        token: "",
    },
    confirmation: {
        alert: "",
        alert_color: "green",
    }
};

function authReducer(state = initialState, action) {
    switch (action.type) {

        case SET_REGISTRATION_DATA:
            return {...state, registration: {...state.registration, [action.name]: action.value}};

        case SET_LOGIN_DATA:
            return {...state, login: {...state.login, [action.name]: action.value}};

        case SET_RESTORE_DATA:
            return {...state, restore: {...state.restore, [action.name]: action.value}};

        case SET_CONFIRMATION_DATA:
            return {...state, confirmation: {...state.confirmation, [action.name]: action.value}};

        default:
            return state;
    }
}

export function setRegistrationData(name, value) {
    return {type: SET_REGISTRATION_DATA, name, value};
}

export function setLoginData(name, value) {
    return {type: SET_LOGIN_DATA, name, value};
}

export function setConfirmationData(name, value) {
    return {type: SET_CONFIRMATION_DATA, name, value};
}

export function setRestoreData(name, value) {
    return {type: SET_RESTORE_DATA, name, value};
}

export const singUp = (request) => {
    return (dispatch) => {
        dispatch(setRegistrationData("loading", true));
        dispatch(setRegistrationData("alert", ""));
        AuthApi.registration(request).then(response => {
            if(response && response.values && response.values.success === true){
                dispatch(setRegistrationData("email", ""));
                dispatch(setRegistrationData("password", ""));
                dispatch(setRegistrationData("password2", ""));
                dispatch(setRegistrationData("alert_color", "green"));
            } else {
                dispatch(setRegistrationData("alert_color", "red"));
            }
            dispatch(setRegistrationData("alert", response.values.message));
            dispatch(setRegistrationData("loading", false));
        });
    };
};

export const singIn = (request) => {
    return (dispatch) => {
        dispatch(setLoginData("loading", true));
        dispatch(setLoginData("alert", ""));
        AuthApi.login(request).then(response => {
            if(response && response.values && response.values.success === true){
                dispatch(setLoginData("email", ""));
                dispatch(setLoginData("alert_color", "green"));
                setCookie(response.values.token, response.values.expiration);

                setTimeout(() => {
                    dispatch(getUserData(response.values.token));
                }, 500)


            } else {
                dispatch(setLoginData("alert_color", "red"));
            }
            dispatch(setLoginData("password", ""));
            dispatch(setLoginData("alert", response.values.message));
            dispatch(setLoginData("loading", false));
        });
    };
};

export const confirmEmail = (request) => {
    return (dispatch) => {
        AuthApi.confirmation(request).then(response => {
            if(response && response.values && response.values.success === true){
                dispatch(setConfirmationData("alert_color", "green"));
            } else {
                dispatch(setConfirmationData("alert_color", "red"));
            }
            dispatch(setConfirmationData("alert", response.values.message));
        });
    };
};


export const restorePassword = () => {
    return (dispatch, getState) => {
        const state = getState();
        dispatch(setRestoreData("loading", true));
        dispatch(setRestoreData("alert", ""));
        AuthApi.restore(state.Auth.restore).then(response => {
            setTimeout(function() {
                if(response && response.values && response.values.success === true){
                    dispatch(setRestoreData("email", ""));
                    dispatch(setRestoreData("password", ""));
                    dispatch(setRestoreData("password2", ""));
                    dispatch(setRestoreData("alert_color", "green"));

                } else {
                    dispatch(setRestoreData("alert_color", "red"));
                }
                dispatch(setRestoreData("alert", response.values.message));
                dispatch(setRestoreData("loading", false));
            }, 900);
        });
    };
};

export const logout = () => {
    return (dispatch) => {
        dispatch(setUserData("authorized", false));
        dispatch(setUserData("token", ""));
        dispatch(setUserData("email", ""));
        removeCookie("token");
    };
};


export default authReducer;