import React from "react";
import {connect} from "react-redux";
import Account from "./Account";
import {getUserData, setUserData, updateUserData} from "../../redux/user-reducer";

class AccountContainer extends React.Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.props.getUserData();
    }

    render() {
        document.title ="Account Settings - Order Watcher";
        return <Account {...this.props} />;
    }
}

const mapStateToProps = state => {
    return {
        user:state.Users,
        alerts:state.Page.alerts,
    };
};

export default connect(
    mapStateToProps, {
        getUserData, setUserData, updateUserData
    })(AccountContainer);