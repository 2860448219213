import React from "react";
import {connect} from "react-redux";
import Login from "./Login";
import {setLoginData, singIn} from "../../../redux/auth-reducer";

class LoginContainer extends React.Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.props.setLoginData("alert", "");
    }

    render() {
        return <Login {...this.props} />;
    }
}

const mapStateToProps = state => {
    return {
        status:state.Auth.status,
        login:state.Auth.login
    };
};

export default connect(
    mapStateToProps, {
        singIn, setLoginData
    })(LoginContainer);