import instance from './Api';
import {getCookie} from "../helpers";

export const BillingApi = {
    invoices(){
        let config = {
            headers:{
                "auth-token": getCookie("token")
            }
        }

        return instance.get(`billing/get-invoices`, config)
            .then(response => {
                return response;
            }).catch(function (error) {
                return error;
            });
    },

    create(period){
        let config = {
            headers:{
                "auth-token": getCookie("token")
            }
        }

        return instance.post(`billing/create-invoice`, {
            period
        }, config)
            .then(response => {
                return response;
            }).catch(function (error) {
                return error.response;
            });
    }

};