import {BillingApi} from "../Api/Billing";
import {setAlerts} from "./page-reducer";
const SET_INVOICES = "SET_INVOICES";
const CHANGE_BILLING = "CHANGE_BILLING";

let initialState = {
    periods:[
        { key: 'month', value: 'month', text: 'Monthly - $26' },
        { key: 'quarter', value: 'quarter', text: 'Quarterly - $75' },
        { key: 'six_months', value: 'six_months', text: 'Semiannual - $140' },
        { key: 'annual', value: 'annual', text: 'Annually - $260' },
    ],
    periods_names: {
        month: "Monthly",
        quarter: "Quarterly",
        six_months: "Semiannual",
        annual: "Annually",
    },
    timeout_update: 600000,
    loading: false,
    period: "month",
    invoices: {
        loading: true,
        data:[]
    }
};

function billingReducer(state = initialState, action) {
    switch (action.type) {

        case SET_INVOICES:
            return {...state, invoices: {...state.invoices, [action.name]: action.value }};
        case CHANGE_BILLING:
            return {...state, [action.name]: action.value};

        default:
            return state;
    }
}


export function setInvoices(name, value) {
    return {type: SET_INVOICES, name, value};
}

export function changeBilling(name, value) {
    return {type: CHANGE_BILLING, name, value};
}

export const getInvoices = () => {
    return (dispatch) => {
        dispatch(setInvoices("loading", true));
        BillingApi.invoices().then(response => {
            if(response.data && response.data.values[0]){
                dispatch(setInvoices("data", response.data.values));
            }
            dispatch(setInvoices("loading", false));
        });
    };
};

export const createInvoice = () => {
    return (dispatch, getState) => {
        const state = getState();
        dispatch(changeBilling("loading", true));
        BillingApi.create(state.Billing.period).then(response => {
            if(response.data && response.data.values){
                dispatch(getInvoices());
                if(response.data.values.success === true){
                    dispatch(setAlerts("color", "green"));
                } else {
                    dispatch(setAlerts("color", "red"));
                }
            }
            setTimeout(function() {
                if(response.data && response.data.values && response.data.values.message){
                    dispatch(setAlerts("message", response.data.values.message));
                }
                dispatch(changeBilling("loading", false));
            }, 500);


        });
    };
};


export default billingReducer;