import {PositionsApi} from "../Api/Positions";

const SET_POSITIONS = "SET_POSITIONS";
const SET_ACTIONS_LIST = "SET_ACTIONS_LIST";
const SET_ACTIONS_STATE = "SET_ACTIONS_STATE";
const ACTIONS_LOADING = "ACTIONS_LOADING";
const POSITIONS_LOADING = "POSITIONS_LOADING";
const SWITCH_MENU = "SWITCH_MENU";

let initialState = {
    menu: "active",
    actions:{
        modal:false,
        loading: false,
        orderId: 0,
        list: {},
    },
    list: {
        loading: false,
        filtered: [],
        active: [],
        history: []
    },
};

function positionsReducer(state = initialState, action) {
    switch (action.type) {

        case SWITCH_MENU:
            return {...state, menu: action.value};

        case SET_POSITIONS:
            return {...state, list: {...state.list, [action.category]: action.value }};

        case POSITIONS_LOADING:
            return {...state, list: {...state.list, loading: action.value }};

        case SET_ACTIONS_STATE:
            let newState = {...state, actions: {...state.actions, modal: action.modal }};
            return {...newState, actions: {...newState.actions, orderId: action.orderID }};

        case ACTIONS_LOADING:
            return {...state, actions: {...state.actions, loading: action.value }};

        case SET_ACTIONS_LIST:
            return {...state, actions: {...state.actions, list: {...state.actions.list, [action.id]: action.value } }};

        default:
            return state;
    }
}

export function switchMenu(value) {
    return {type: SWITCH_MENU, value};
}

export function positionsLoading(value) {
    return {type: POSITIONS_LOADING, value};
}

export function setPositions(category, value) {
    return {type: SET_POSITIONS, category, value};
}

export function actionsLoading(value) {
    return {type: ACTIONS_LOADING, value};
}

export function setActionState(modal, orderID) {
    return {type: SET_ACTIONS_STATE, modal, orderID};
}

export function setActionsList(id, value) {
    return {type: SET_ACTIONS_LIST, id, value};
}

export const getPositions = (id, active) => {
    return (dispatch) => {
        dispatch(positionsLoading(true));
        PositionsApi.getPositions(id, active).then(response => {
            if(response.values){
                dispatch(setPositions((active ? "active" : "history"), response.values));
            }
            dispatch(positionsLoading(false));
        });
    };
};

export const getActions = (orderId) => {
    return (dispatch) => {
        dispatch(actionsLoading(true));
        dispatch(setActionState(true, orderId));
        PositionsApi.getActions(orderId).then(response => {
            if(response.values){
                dispatch(setActionsList(orderId, response.values));
            }
            setTimeout(function() {
                dispatch(actionsLoading(false));
            }, 300);

        });
    };
};


export default positionsReducer;