import React from "react";
import {connect} from "react-redux";
import AddTrader from "./AddTrader";
import {subscribe, updateTraders} from "../../../redux/traders-reducer";

class AddTraderContainer extends React.Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {}

    render() {
        return <AddTrader {...this.props} />;
    }
}

const mapStateToProps = state => {
    return {
        traders:state.Traders,
        user:state.Users,
    };
};

export default connect(
    mapStateToProps, {
        subscribe, updateTraders
    })(AddTraderContainer);