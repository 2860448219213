import React from "react";
import {connect} from "react-redux";
import Restore from "./Restore";
import {restorePassword, setRestoreData} from "../../../redux/auth-reducer";

class RestoreContainer extends React.Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        const urlParams = new URLSearchParams(window.location.search);
        this.props.setRestoreData("email", urlParams.get('email'))
        this.props.setRestoreData("token", urlParams.get('code'))
    }

    render() {
        return <Restore {...this.props} />;
    }
}

const mapStateToProps = state => {
    return {
        restore:state.Auth.restore
    };
};

export default connect(
    mapStateToProps, {
        restorePassword, setRestoreData
    })(RestoreContainer);