import instance from './Api';

export const AuthApi = {

    user_data(token){

        return instance.post(`auth/user`, {
            "token": token,
        })
            .then(response => {
                return response.data;
            }).catch(function (error) {
                return {
                    status: false,
                    error
                };
            });
    },
    registration(data){
        return instance.post(`auth/registration`, {
            "name": data.name,
            "email": data.email,
            "password": data.password,
            "password2": data.password2
        })
            .then(response => {
                return response.data;
            }).catch(function (error) {
                return error.response.data;
            });
    },
    login(data){
        return instance.post(`auth/login`, {
            "email": data.email,
            "password": data.password
        })
            .then(response => {
                return response.data;
            }).catch(function (error) {
                return error.response.data;
            });
    },
    restore(data){
        let email = data.email;
        let password = data.password;
        let password2 = data.password2;
        let token = data.token;
        return instance.post(`auth/restore`, {email, password, password2, token })
            .then(response => {
                return response.data;
            }).catch(function (error) {
                return error.response.data;
            });
    },
    confirmation(data){
        return instance.get(`auth/confirmation?code=${data.code}&email=${data.email}`)
            .then(response => {
                return response.data;
            }).catch(function (error) {
                return error.response.data;
            });
    }

};