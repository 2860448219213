import React from "react";
import {Button, Icon, Input, Message} from "semantic-ui-react";
const Registration = (props) => {

    return (
        <form id={'registration'} className={"form auth section"} onSubmit={(event) => {
            event.preventDefault();
            props.singUp(props.registration);
        }}>
            <h2>Create Personal Account</h2>

            <Input iconPosition='left' placeholder='Name' value={props.registration.name} required type='text' maxLength='64' onChange={(e) => {
                props.setRegistrationData('name', e.target.value);
            }}>
                <Icon name='user' />
                <input />
            </Input>

            <Input iconPosition='left' placeholder='E-mail' value={props.registration.email} required type='email' maxLength='32' onChange={(e) => {
                props.setRegistrationData('email', e.target.value);
            }}>
                <Icon name='at' />
                <input />
            </Input>

            <Input iconPosition='left' placeholder='Password' value={props.registration.password} required type='password' name='password' minLength='6' maxLength='32' onChange={(e) => {
                props.setRegistrationData('password', e.target.value);
            }}>
                <Icon name='unlock alternate' />
                <input />
            </Input>

            <Input iconPosition='left' placeholder='Confirm password' value={props.registration.password2} required type='password' name='password2' minLength='6' maxLength='32' onChange={(e) => {
                props.setRegistrationData('password2', e.target.value);
            }}>
                <Icon name='unlock alternate' />
                <input />
            </Input>
            <Message color={props.registration.alert_color} hidden={(!props.registration.alert)}>{props.registration.alert}</Message>
            <div>
                By creating an account, I agree to Order Watcher's <a href={"#"}>
                <b>Terms of Service</b></a> and
                <a href={"#"}> <b>Privacy Policy</b></a>.
            </div>
            <Button loading={props.registration.loading} disabled={props.registration.loading} color='black'>Sing Up</Button>
        </form>
    )

};

export default Registration;