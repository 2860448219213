import React from "react";
import {connect} from "react-redux";
import Actions from "./Actions";
import {setActionState} from "../../../../../redux/positions-reducer";

class ActionsContainer extends React.Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {}

    render() {
        return <Actions {...this.props} />;
    }
}

const mapStateToProps = state => {
    return {
        positions:state.Positions,
        user:state.Users,
    };
};

export default connect(
    mapStateToProps, {
        setOrderData: setActionState
    })(ActionsContainer);