import React from "react";
import {connect} from "react-redux";
import Leaderboard from "./Leaderboard";
import {getUserData, traderNotifyOff, traderNotifyOn} from "../../../redux/user-reducer";
import {getLeaderboard, getTraders, subscribe, unsubscribe, updateTraders} from "../../../redux/traders-reducer";

class LeaderboardContainer extends React.Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.props.getLeaderboard();
    }

    render() {
        document.title ="Binance Leaderboard Tracking & Notification System - Order Watcher";
        document.getElementsByTagName("meta")[1].content ="Binance Futures Leaderboard automatic positions tracking system.";
        return <Leaderboard {...this.props} />;
    }
}

const mapStateToProps = state => {
    return {
        user:state.Users,
        traders:state.Traders,
    };
};

export default connect(
    mapStateToProps, {
        getUserData, getTraders, updateTraders, traderNotifyOn, traderNotifyOff, getLeaderboard, subscribe, unsubscribe
    })(LeaderboardContainer);