import React from "react";
import {connect} from "react-redux";
import Index from "./Index";
import {setMenu} from "../../../redux/page-reducer";

class IndexContainer extends React.Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.props.setMenu("status", "closed");
        //this.props.setMenu("type", "transparent");
    }

    componentWillUnmount() {
        //this.props.setMenu("type", "normal");
    }

    render() {
        document.title ="Order Watcher - Top Crypto Traders Position Watching System";
        document.getElementsByTagName("meta")[1].content ="Automatic tracking and notification system of top traders futures positions from Binance Leaderboard.";
        return <Index {...this.props} />;
    }
}

const mapStateToProps = state => {
    return {
        //blogs:state.Blogs.blogs
    };
};

export default connect(
    mapStateToProps, {
        setMenu
    })(IndexContainer);