import instance from './Api';
import {getCookie} from "../helpers";

export const NotificationApi = {

    traderOn(id){

        let config = {
            headers:{
                "auth-token": getCookie("token")
            }
        }

        return instance.post(`notification/trader/on`, {
            "id": id
        }, config)
            .then(response => {
                return response.data;
            }).catch(function (error) {
                return error.response.data;
            });
    },
    traderOff(id){

        let config = {
            headers:{
                "auth-token": getCookie("token")
            }
        }

        return instance.post(`notification/trader/off`, {
            "id": id
        }, config)
            .then(response => {
                return response.data;
            }).catch(function (error) {
                return error.response.data;
            });
    },
    update(settings){

        let config = {
            headers:{
                "auth-token": getCookie("token")
            }
        }

        return instance.post(`notification/update`, {
            "notification_settings": settings
        }, config)
            .then(response => {
                return response.data;
            }).catch(function (error) {
                return error.response.data;
            });
    },

};