import {OptionsApi} from "../Api/Options";
const SET_CURRENCIES = "SET_CURRENCIES";

let initialState = {
    currencies: {
        data:[]
    }
};

function optionsReducer(state = initialState, action) {
    switch (action.type) {

        case SET_CURRENCIES:
            return {...state, currencies: {...state.currencies, data: action.value }};

        default:
            return state;
    }
}


export function setCurrencies(value) {
    return {type: SET_CURRENCIES, value};
}

export const getCurrencies = (request) => {
    return (dispatch) => {
        OptionsApi.currencies(request).then(response => {
            if(response.data && response.data.values.success){
                dispatch(setCurrencies(response.data.values.currencies));
            }
        });
    };
};


export default optionsReducer;