import React from "react";
import {Button, Dimmer, Icon, Loader, Tab} from "semantic-ui-react";
import avatar from "../../../images/trader-avatar.png"
import {usd, setClassName} from "../../../helpers";
import PerformanceDays from "./PerformanceDays"
import PerformancePeriods from "./PerformancePeriods"
import Positions from "./Positions/PositionsContainer"
import PositionsHistory from "./Positions/PositionsHistoryContainer"
import Actions from "./Positions/Actions/ActionsContainer"
import {NavLink} from "react-router-dom";
import Alerts from "../../Alerts/Alerts";

const positionsPanes = [
    { menuItem: 'Active Positions', render: () => <Positions /> },
    { menuItem: 'Positions History', render: () => <PositionsHistory /> },
]

const TraderProfile = (props) => {

    let isSubscribed = props.user.traders.find((id)=>{
        return (id === props.profile.id)
    })

    const performancePanes = [
        { menuItem: 'Performance Dates', active: true, render: () => <PerformanceDays {...props} /> },
        { menuItem: 'Exact Periods', render: () => <PerformancePeriods {...props} /> },
    ]

    return (
        <div id={"trader-profile"} className={'dashboard'}>
            <Alerts user={props.user} />
            <NavLink to={"/watchlist"}>
                <Button primary icon labelPosition='left'>
                    <Icon name='chevron left' />
                    Back to Watchlist
                </Button>
            </NavLink>
            <section id={'basic-info'} className={'section'}>
                <div className={'avatar'}><img alt={'Trader avatar'} src={(props.profile.photo ? props.profile.photo : avatar)}/></div>
                <div className={'info'}>
                    <h2 className={'name'}>
                        <a target={"_blank"} href={'https://www.binance.com/en/futures-activity/leaderboard/user/um?encryptedUid=' + props.profile.uid}>
                            {props.profile.name}
                            <Icon name='external square alternate' />
                        </a>
                    </h2>
                    <div className={'followers'}>{props.profile.follower_count} Followers</div>
                    {(
                            props.profile.twitter ?
                                <Button floated={'left'} size='tiny' color='twitter' onClick={()=>{
                                    window.open(props.profile.twitter, '_blank');
                                }}>
                                    <Icon name='twitter' /> Twitter
                                </Button> : ""
                        )}

                </div>
                <div className={'performance'}>
                    <div>
                        <div className={'title'}>Total PNL (USD)</div>
                        <div className={setClassName(props.profile.all_pnl, "index")}>{usd.format(props.profile.all_pnl)}</div>
                    </div>
                    <div>
                        <div className={'title'}>Total ROI</div>
                        <div className={setClassName(props.profile.all_roi, "index")}>{props.profile.all_roi}%</div>
                    </div>
                </div>
                <div className={'buttons'}>
                    {(
                        isSubscribed ?
                            <Button color='yellow' onClick={()=>{
                                props.unsubscribe(props.profile.id)
                            }}>Unsubscribe</Button> :
                            <Button color='teal' onClick={()=>{
                                props.subscribe(props.profile.uid)
                            }}>Subscribe</Button>
                    )}
                </div>
            </section>
            <Tab menu={{ secondary: true, pointing: true }} panes={performancePanes} />
            <Tab menu={{ secondary: true, pointing: true }} panes={positionsPanes} onTabChange={ (event, data)=>{
                props.switchMenu( (data.activeIndex ? "history" : "active") )
            } } />
            <Actions />
        </div>
    )
};

export default TraderProfile;