import React from "react";
import {usd, setClassName} from "../../../helpers";

const PerformanceDays = (props) => {

    return (
        <section id={'data-overview'} className={'section exact-period'}>
            <div className={'performance'}>
                <div className={'title'}>This week ROI</div>
                <div className={setClassName(props.profile.exact_weekly_roi, "index")}>{props.profile.exact_weekly_roi}%</div>
                <div className={'title'}>This week PNL (USD)</div>
                <div className={setClassName(props.profile.exact_weekly_pnl, "index")}>{usd.format(props.profile.exact_weekly_pnl)}</div>
            </div>
            <div className={'performance'}>
                <div className={'title'}>Current month ROI</div>
                <div className={setClassName(props.profile.exact_monthly_roi, "index")}>{props.profile.exact_monthly_roi}%</div>
                <div className={'title'}>Current month PNL (USD)</div>
                <div className={setClassName(props.profile.exact_monthly_pnl, "index")}>{usd.format(props.profile.exact_monthly_pnl)}</div>
            </div>
            <div className={'performance'}>
                <div className={'title'}>This year ROI</div>
                <div className={setClassName(props.profile.exact_yearly_roi, "index")}>{props.profile.exact_yearly_roi}%</div>
                <div className={'title'}>This year PNL (USD)</div>
                <div className={setClassName(props.profile.exact_yearly_pnl, "index")}>{usd.format(props.profile.exact_yearly_pnl)}</div>
            </div>
        </section>
    )
};

export default PerformanceDays;