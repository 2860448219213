import React from "react";
import {NavLink} from "react-router-dom";
import logo from "../../images/order_watcher-logo-minimal.svg";
import {Button, Icon} from "semantic-ui-react";

const setNavigation = (props) => {

    let type = (props.user.authorized ? "private" : "public");

    const menu = {
        public: [
            {to: "/", icon: "home", text: "Home"},
            {to: "/leaderboard", icon: "users", text: "Leaderboard"},
            {to: "/login", icon: "unlock", text: "Login"},
            {to: "/registration", icon: "user", text: "Sing Up"},
        ],
        private: [
            {to: "/", icon: "home", text: "Home"},
            {to: "/leaderboard", icon: "users", text: "Leaderboard"},
            {to: "/watchlist", icon: "eye", text: "My Watchlist"},
            {to: "/billing", icon: "dollar", text: "Billing"},
            {to: "/account", icon: "user circle", text: "Account"},
        ],
    }

    let navi = menu[type].map((value)=>{
        return <NavLink onClick={()=>{
            props.setMenu("status", "closed" )
        }} to={value.to}><Icon name={value.icon} />{value.text}</NavLink>
    })

    return (
        <nav id={'right-nav'} className={"navi " + props.page.menu.status}>
            {navi}
        </nav>
    )

}

const Header = (props) => {
    return (
        <header id={'header'} className={props.page.menu.type}>
            <NavLink id={'logo'} className={"navi"} to={"/"}><img src={logo} alt={'Order Watcher Logo'}/></NavLink>
            {(
                //props.user.token && props.user.authorized ? authorizedNavi(props) : nonAuthorizedNavi()
                setNavigation(props)
            )}
            <nav className={"navi mobile " + props.page.menu.status}>
                <Button
                    onClick={()=>{
                        props.setMenu("status", (props.page.menu.status === "closed" ? "opened" : "closed") )
                    }}
                    icon
                    basic
                    color={(props.page.menu.status === 'closed' ? 'violet' : 'orange')}
                    size='small'
                ><Icon name={(props.page.menu.status === 'closed' ? 'bars' : 'close')} /></Button>
            </nav>
        </header>
    )
};

export default Header;