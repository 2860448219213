import React from "react";
import {connect} from "react-redux";
import Confirmation from "./Confirmation";
import {confirmEmail} from "../../../redux/auth-reducer";

class ConfirmationContainer extends React.Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {

        const urlParams = new URLSearchParams(window.location.search);

        this.props.confirmEmail({
            code: urlParams.get('code'),
            email: urlParams.get('email')
        });
    }

    render() {
        return <Confirmation {...this.props} />;
    }
}

const mapStateToProps = state => {
    return {
        confirmation:state.Auth.confirmation
    };
};

export default connect(
    mapStateToProps, {
        confirmEmail
    })(ConfirmationContainer);