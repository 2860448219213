import instance from './Api';
import {getCookie} from "../helpers";

export const TradersApi = {
    leaderboard(){
        return instance.get(`leaderboard`)
            .then(response => {
                return response.data;
            }).catch(function (error) {
                return error.response.data;
            });
    },
    list(data){

        let config = {
            headers:{
                "auth-token": getCookie("token")
            }
        }

        return instance.post(`traders`, {
            "traders": data
        }, config)
            .then(response => {
                return response.data;
            }).catch(function (error) {
                return error.response.data;
            });
    },
    profile(id){

        let config = {
            headers:{
                "auth-token": getCookie("token")
            }
        }

        return instance.post(`traders/${id}`, {}, config)
            .then(response => {
                return response.data;
            }).catch(function (error) {
                return error.response.data;
            });
    },
    subscribe(uid){

        let config = {
            headers:{
                "auth-token": getCookie("token")
            }
        }

        return instance.post(`traders/subscribe`, {
            "uid": uid
        }, config)
            .then(response => {
                return response.data;
            }).catch(function (error) {
                return error.response.data;
            });
    },
    unsubscribe(id){

        let config = {
            headers:{
                "auth-token": getCookie("token")
            }
        }

        return instance.post(`traders/unsubscribe`, {
            "id": id
        }, config)
            .then(response => {
                return response.data;
            }).catch(function (error) {
                return error.response.data;
            });
    },

};