import axios from "axios";
let qs = require('qs');

export let prepare = (array) => {
    return qs.stringify(array);
};

axios.interceptors.response.use(
    function (response) {
        if (response.data) {
            // return success
            if (response.status === 200 || response.status === 201) {
                return response;
            }
            // reject errors & warnings
            return Promise.reject(response);
        }

        // default fallback
        return Promise.reject(response);
    },
    function (error) {
        // if the server throws an error (404, 500 etc.)
        return Promise.reject(error);
    }
);

let instance = axios.create({
    withCredentials:true,
    baseURL: 'https://api.orderwatcher.com/'
    //baseURL: 'http://localhost:8888/'
});

export default instance;