import React from "react";
import {Button, Dimmer, Icon, Loader, Popup} from "semantic-ui-react";
import {NavLink} from "react-router-dom";
import AddTrader from "../AddTrader/AddTraderContainer";
import {numb, setClassName, usd} from "../../../helpers";

let setTraders = (props) => {

    const data = props.traders.list

    if(props.traders.list_loading && !data[0]){
        return (
            <Dimmer active inverted>
                <Loader inverted>Loading</Loader>
            </Dimmer>
        )
    }

    if(!data[0]) {
        return (
            <h3>There are no traders to track. Add your trader to the list from Binance Leaderboard.</h3>
        );
    }

    return (
        data.map((value, index) => {

            let isActive = props.user.notification_settings.traders.find((id)=>{
                return (id === value.id)
            })

            let NotifyBtn = (
                isActive ?
                    <Button loading={value.loading} disabled={value.loading} icon={'bell'} basic color='teal' onClick={()=>{
                        props.traderNotifyOff(value.id, index)
                    }}>
                    </Button> :
                    <Button loading={value.loading} disabled={value.loading} icon={'bell slash'} basic color='grey' onClick={()=>{
                        props.traderNotifyOn(value.id, index)
                    }}>
                    </Button>
            )



            let position_shared = '';

            if(!value.position_shared){
                position_shared = (
                    <Popup
                        content={"This trader has hidden his positions. The data will be updated when positions become available again."}
                        trigger={<Icon className={'hidden'} color='red' name='eye slash outline' />}
                    />
                )
            }

            return (
                <div className={'body row'}>
                    <div className={'notify'}>{NotifyBtn}</div>
                    <div className={'name'}>
                        <NavLink to={"/trader/" + value.id}>
                            {value.name}
                        </NavLink>
                        {(value.opened_positions ? <span className={'orders'}>({value.opened_positions})</span> : "" )}
                        {position_shared}
                    </div>
                    <div className={setClassName(value.monthly_pnl, "pnl")}><span className={'sum'}>{usd.format(value.monthly_pnl)}</span><span className={'roe'}>{numb.format(value.monthly_roi)}%</span></div>
                    <div className={setClassName(value.all_pnl, "pnl no-mob")}><span className={'sum'}>{usd.format(value.all_pnl)}</span><span className={'roe'}>{numb.format(value.all_roi)}%</span></div>
                    <div className={'btn no-mob'}>
                        <NavLink to={"/trader/" + value.id}>
                            <Button primary icon labelPosition='right'>
                                Details
                                <Icon name='chart pie' />
                            </Button>
                        </NavLink>
                    </div>
                </div>
            );
        })
    )
};

const Traders = (props) => {
    return (
        <div id={"traders"}>
            <h2><Icon name='eye' /> Traders Watchlist</h2>
            <AddTrader />

            <div className={'table section'}>
                <div className={'header row'}>
                    <div className={'notify'}></div>
                    <div className={'name'}>Name</div>
                    <div className={'pnl'}>Monthly PNL (ROE %)</div>
                    <div className={'pnl no-mob'}>Total PNL (ROE %)</div>
                    <div className={'btn no-mob'}></div>
                </div>
                {setTraders(props)}
            </div>
        </div>
    )
};

export default Traders;