import React from "react";
import {Button, Icon, Input, Message} from "semantic-ui-react";

const enterEmail = (props) => {
    return (
        <>
            <h2>Enter your email for password recovery</h2>
            <Message color={props.restore.alert_color} hidden={(!props.restore.alert)}>{props.restore.alert}</Message>
            <Input value={props.restore.email} iconPosition='left' placeholder='E-mail' required type={'email'} maxLength='32' onChange={(e)=>{
                props.setRestoreData("email", e.target.value)
            }}>
                <Icon name='at' />
                <input />
            </Input>
            <Button loading={props.restore.loading} disabled={props.restore.loading} primary>Restore</Button>
        </>
    )
}

const enterPassword = (props) => {
    return (
        <>
            <h2>Enter your new password:</h2>
            <Message color={props.restore.alert_color} hidden={(!props.restore.alert)}>{props.restore.alert}</Message>
            <Input iconPosition='left' placeholder='Password' value={props.restore.password} required type='password' name='password' minLength='6' maxLength='32' onChange={(e) => {
                props.setRestoreData('password', e.target.value);
            }}>
                <Icon name='unlock alternate' />
                <input />
            </Input>

            <Input iconPosition='left' placeholder='Confirm password' value={props.restore.password2} required type='password' name='password2' minLength='6' maxLength='32' onChange={(e) => {
                props.setRestoreData('password2', e.target.value);
            }}>
                <Icon name='unlock alternate' />
                <input />
            </Input>
            <Button loading={props.restore.loading} disabled={props.restore.loading} positive>Create New Password</Button>
        </>
    )
}

const Restore = (props) => {

    return (
        <form id={'login'} className={"form auth section"} onSubmit={(event) => {
            event.preventDefault();
            props.restorePassword();
        }}>
            {(
                props.restore.token > 11111111 && props.restore.token < 99999999 ?
                    enterPassword(props) :
                    enterEmail(props)
            )}

        </form>
    )
};

export default Restore;