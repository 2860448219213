import React from "react";
import {Button, Dimmer, Loader, Modal} from "semantic-ui-react";
import {numb, setClassName, usd} from "../../../../../helpers";
import moment from "moment";



const setActions = (actions) => {

    if(!actions || !actions[0]){
        return <h3>No actions for this position!</h3>
    }

    let totalProfit = 0;

    const actionsTable = actions.map((value) => {

        totalProfit += parseFloat(value.profit);

        return (
            <div className={'body row'}>
                <div className={'action'}>{value.action}</div>
                <div className={'price'}>{value.price}</div>
                <div className={'amount'}>{numb.format(value.amount)}</div>
                <div className={setClassName(value.pnl, "pnl")}>{usd.format(value.pnl)}</div>
                <div className={setClassName(value.profit, "pnl")}>{usd.format(value.profit)}</div>
                <div className={'percent'}>{(value.percent_change ? numb.format(value.percent_change) + "%" : "" )}</div>
                <div className={'time'}>{moment(value.time).calendar()}</div>
            </div>
        );
    })

    return (
        <div id={'actions'}>
            <div className={'table'}>
                <div className={'header row'}>
                    <div className={'action'}>Action</div>
                    <div className={'price'}>Price</div>
                    <div className={'amount'}>Size</div>
                    <div className={'pnl'}>PNL</div>
                    <div className={'profit'}>Profit/Loss</div>
                    <div className={'percent'}>Percent</div>
                    <div className={'time'}>Time</div>
                </div>
                {actionsTable}
            </div>
            <div className={'total-profit'}><b>Total Profit</b>: {usd.format(totalProfit)}</div>
        </div>
    );

}


const Actions = (props) => {

    if(!props.positions.actions.modal){
        return ""
    }

    let data = <Dimmer active inverted><Loader inverted content='Loading' /></Dimmer>

    let header = ""

    if(props.positions.actions.loading === false){
        data = setActions(props.positions.actions.list[props.positions.actions.orderId])
    }

    let position = props.positions.list[props.positions.menu].find(({id}) => {
        return id === props.positions.actions.orderId
    })

    header = (
        <div className={'symbol'}>
            <span className={'ticker'}>{position.symbol}</span>
            <span className={'direction ' + position.direction.toLowerCase()}>{position.direction}</span>
            <span className={'leverage'}>{position.leverage}x</span>
        </div>
    )

    return (
        <Modal
            size={'large'}
            open={props.positions.actions.modal}
            onClose={() =>{} }
        >
            <Modal.Header>{header}</Modal.Header>
            <Modal.Content>
                {data}
            </Modal.Content>
            <Modal.Actions>
                <Button negative onClick={ ()=>{
                    props.setOrderData(false, 0)
                } }>
                    Close
                </Button>
            </Modal.Actions>
        </Modal>
    )
};

export default Actions;