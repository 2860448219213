import React from "react";
import {Button, Form, Icon, Message} from "semantic-ui-react";
import {getEncryptedUid} from "../../../helpers";
import {NavLink} from "react-router-dom";
const AddTrader = (props) => {

    return (
        <section className={'add-trader-form'}>
        <Message className={'custom'} info>
            <Icon name='question circle outline' />
            <Message.Header>How to start tracking the positions of other traders?</Message.Header>
            <p>First of all, you need to add traders to your watchlist.<br/>
                You can subscribe to the best traders from the <b><NavLink to="/leaderboard">our leaderboard</NavLink></b> or go to the <b><a target={'_blank'} href={'https://www.binance.com/en/futures-activity/leaderboard'}>Binance Futures Leaderboard</a></b>,
                copy the URL of any trader you like, paste it into the field below and press the <b>"Add Trader to Watchlist"</b> button.<br/>
                <b>You can trade absolutely on any crypto exchange</b>, you will simply use signals from traders from the Binance exchange.
            </p>
        </Message>
        <div id={"add-trader"}>
            <Form onSubmit={(event) => {
                event.preventDefault();
                props.subscribe(getEncryptedUid(props.traders.new_leader_url))
            }}>
                <Form.Group>
                    <Form.Input
                        icon='linkify'
                        width={5}
                        iconPosition='left'
                        placeholder='Binance Leader URL'
                        required
                        type={'text'}
                        minLength='64'
                        maxLength='128'
                        value={props.traders.new_leader_url}
                        onChange={(e) => {
                            props.updateTraders('new_leader_url', e.target.value);
                        }}
                    />
                    <Button
                        loading={props.traders.loading}
                        disabled={(props.traders.list.length >= props.user.watchlist_limit ? true : props.traders.loading)}
                        color='teal'
                        floated='right'
                        icon
                        labelPosition='left'
                    >
                        <Icon name='add user' />
                        Add Trader to Watchlist
                    </Button>
                </Form.Group>
            </Form>
            <div className={'watchlist-counter'}>{props.traders.list.length} / {props.user.watchlist_limit}</div>
        </div>
        </section>
    )
};

export default AddTrader;