import React from "react";
import {connect} from "react-redux";
import Dashboard from "./Dashboard";
import {getUserData, traderNotifyOff, traderNotifyOn} from "../../redux/user-reducer";
import {getTraders, updateTraders} from "../../redux/traders-reducer";

class DashboardContainer extends React.Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {}

    render() {
        document.title ="My Watchlist - Order Watcher";
        return <Dashboard {...this.props} />;
    }
}

const mapStateToProps = state => {
    return {
        user:state.Users,
        traders:state.Traders,
    };
};

export default connect(
    mapStateToProps, {
        getUserData, getTraders, updateTraders, traderNotifyOn, traderNotifyOff
    })(DashboardContainer);