const moment = require("moment");
const setCookie = (token, expiration) => {
    let expires = "; expires=" + moment(expiration).toDate();
    document.cookie = "token=" + token  + expires + "; path=/";
}

const getCookie = (name) => {
    let nameEQ = name + "=";
    let ca = document.cookie.split(';');
    for(let i=0;i < ca.length;i++) {
        let c = ca[i];
        while (c.charAt(0)===' ') c = c.substring(1,c.length);
        if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length,c.length);
    }
    return null;
}

const removeCookie = (name) => {
    document.cookie = name +'=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
}

const usd = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
});

const numb = new Intl.NumberFormat('en-US');

const symbolsValueFormat = (symbols) => {

    let options = [];

    symbols.forEach((value)=>{
        options.push({symbol: value})
    })

    return options;

}

const getEncryptedUid = (url) => {

    let encryptedUid = false;

    if( url.includes("futures-activity/leaderboard/user/um?encryptedUid=") ){
        encryptedUid = url.split( 'encryptedUid=' );
        encryptedUid = encryptedUid[1];
    }

    return encryptedUid

}

const setClassName = (data, className) => {
    return (data >= 0 ? "positive " + className : "negative " + className)
}

module.exports = {setCookie, getCookie, removeCookie, usd, numb, symbolsValueFormat, getEncryptedUid, setClassName};