import React from "react";
import {connect} from "react-redux";
import Registration from "./Registration";
import {singUp, setRegistrationData} from "../../../redux/auth-reducer";

class RegistrationContainer extends React.Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {}

    render() {
        return <Registration {...this.props} />;
    }
}

const mapStateToProps = state => {
    return {
        registration:state.Auth.registration
    };
};

export default connect(
    mapStateToProps, {
        singUp, setRegistrationData
    })(RegistrationContainer);