import React from "react";
import {Button, Icon, Loader, Message} from "semantic-ui-react";
import {NavLink} from "react-router-dom";
import {numb, setClassName, usd} from "../../../helpers";
import avatar from "../../../images/trader-avatar.png";
import binance_logo from "../../../images/Binance_Logo.svg"
import FAQ from "../../FAQ/FAQ"

let setTraders = (props) => {

    const data = props.traders.leaderboard

    if(props.traders.list_loading && !data[0]){
        return (
            <Loader active size='medium'>Loading...</Loader>
        )
    }

    if(!data[0]) {
        return (
            <h3>There are no leaderboard traders to track.</h3>
        );
    }

    return (
        data.map((value, index) => {

            let isSubscribed = false;

            if(props.user.authorized){
                isSubscribed = props.user.traders.find((id)=>{
                    return (id === value.id)
                })
            }

            return (
                <div className={'trader-card'}>

                    <div className={'header'}>
                        <div className={'name'}>
                            <a className={'avatar'} target={"_blank"} href={'https://www.binance.com/en/futures-activity/leaderboard/user/um?encryptedUid=' + value.uid}>
                                <img className={"binance-logo"} alt={'Binance Logo'} src={binance_logo}/>
                                <img alt={'Trader avatar'} src={(value.photo ? value.photo : avatar)}/>
                            </a>
                            <NavLink to={"/trader/" + value.id}>
                                {value.name}
                            </NavLink>
                            <div className={'orders'}><b>{value.follower_count}</b> Followers</div>
                        </div>
                        <div className={'follow'}>
                            {(
                                isSubscribed ?
                                    <Button fluid compact color='grey' onClick={()=>{
                                        props.unsubscribe(value.id)
                                    }}>Unfollow</Button> :
                                    (
                                        props.user.authorized ?
                                            <Button fluid compact color='blue' onClick={()=>{
                                                props.subscribe(value.uid)
                                            }}>Follow</Button> :
                                            <NavLink to={"/login"}><Button fluid compact color='blue'>Follow</Button></NavLink>
                                    )
                            )}
                        </div>
                    </div>

                    <div className={'data'}>
                        <div className={setClassName(value.monthly_pnl, "pnl")}>
                            <h4>Monthly PNL</h4>
                            <span className={'sum'}>{usd.format(value.monthly_pnl)}</span>
                            <span className={'roe'}>{numb.format(value.monthly_roi)}%</span>
                        </div>
                        <div className={setClassName(value.all_pnl, "pnl")}>
                            <h4>Total PNL</h4>
                            <span className={'sum'}>{usd.format(value.all_pnl)}</span>
                            <span className={'roe'}>{numb.format(value.all_roi)}%</span>
                        </div>
                    </div>
                </div>
            );
        })
    )
};

const Leaderboard = (props) => {



    return (
        <div id={"dashboard"} className={'dashboard leaderboard'}>
            <h1><Icon name='users' /> Futures Top Traders Leaderboard</h1>
            <Message
                onDismiss={()=>{
                    props.updateTraders("alert", "")
                }}
                color={props.traders.alert_color}
                hidden={(!props.traders.alert)}>{props.traders.alert}
            </Message>
            <h2 className={'leaders-info'}>Get notifications on your smartphone and copy trades of the best Binance traders!</h2>
            <div className={"leaderboard-description"}>
                <p><Icon name='angle right' /> <b>This leaderboard features the top traders who have the most stable percentage of successful trades and who occupy the top positions on the Binance leaderboard.</b></p>
                <p><Icon name='angle right' /> You can subscribe to any of them and track their activities in real time.</p>
                <p><Icon name='angle right' /> You will know when a trader opened, increased, lowered or closed a trading position. This will give you confidence and understanding of the market at the current moment, which in turn will greatly increase the chances of a successful trade.</p>
            </div>
            <div className={'leaders'}>{setTraders(props)}</div>
            <FAQ/>
        </div>
    )
};

export default Leaderboard;