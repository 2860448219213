import React from "react";
import {NavLink} from "react-router-dom";
import '../../../style/public.css';
import {Icon} from "semantic-ui-react";
import { Link } from 'react-scroll'
import iphone from '../../../images/apple-iphone-13.png'
const Index = (props) => {

    return (
        <div id={"public"} className={'index'}>
            <section id={'top'} className={'promo darkness'}>
                <div className={'midland center mxw-920'}>
                    <h1>Leaderboard Traders <span>Position Tracking System</span></h1>
                    <div className={'context'}><h3><span>Track positions and receive real-time notifications</span> of your favorite leading traders from the <span>Binance Leaderboard</span>.</h3></div>
                    <div className={'context'}>
                        <NavLink className={'btn'} to={"/registration"}><Icon name='checkmark' />Try <span>7-day trial</span> for free</NavLink>
                        <Link className={'btn b2'} to="about" spy={true} smooth={true} duration={500}>About Order Watcher <Icon name='angle double down' /></Link>
                    </div>
                </div>
            </section>
            <section id={'about'}>
                <div className={'midland center mxw-920'}>
                    <h2>About <span>Order Watcher</span></h2>
                    <div className={'context grid double'}>
                        <div>
                            <h3>Order Watcher is a system for automatically tracking the positions of other crypto traders.</h3>
                            <p>Choose the best traders and trade just by copying their position.</p>
                            <p>You can <b>select any trader</b> from the Binance Leaderboard, add them to the our system and receive real-time <b>notifications on your phone</b> when a position is <b>opened, increased, decreased, closed or liquidated</b>.</p>
                            <p>The personal account has a convenient toolkit for viewing the history of orders, all open positions and actions on them.</p>
                        </div>
                        <img src={iphone} />
                    </div>

                </div>
            </section>
            <section id={'how-to-start'}>
                <div className={'midland mxw-920'}>
                    <h2>How to <span>start?</span></h2>
                    <div className={'context'}>
                        <ol>
                            <li><NavLink to={"/registration"}>Register an account</NavLink> and confirm your email</li>
                            <li>Subscribe to <a target={'_blank'} href={'https://t.me/OrderWatcherRobot'}>our <Icon name='telegram plane' />Telegram bot</a></li>
                            <li>Set up a watchlist of traders in a personal account</li>
                            <li>Receive position notifications in telegram</li>
                        </ol>
                    </div>

                </div>
            </section>
            <section id={'how-to-use'}>
                <div className={'midland center mxw-1124'}>
                    <h2>Where to apply the <span>Order Watcher data</span>?</h2>
                    <div className={'context'}>
                        <p>If you are new to the cryptocurrency market, then you just need to repeat the steps and copy the transactions of experienced traders. So you will quickly start making money on the stock exchange and learn how to trade correctly.</p>
                        <p>For experienced traders, the Order Watcher is a powerful tool for finding great entry points into a position. In addition to your expertise in the cryptocurrency market, you will add the experience of people who have earned millions on the exchange.</p>
                    </div>

                </div>
            </section>
        </div>
    )
};

export default Index;