const SET_ALERTS = "SET_ALERTS";
const SET_MENU = "SET_MENU";

let initialState = {
    alerts:{
        message: "",
        color: "green",
    },
    menu:{
        type: "normal",
        status: "closed",
    },
    content: {
        index:{

        }
    }
};

function pageReducer(state = initialState, action) {
    switch (action.type) {

        case SET_ALERTS:
            return {...state, alerts: {...state.alerts, [action.name]: action.value }};

        case SET_MENU:
            return {...state, menu: {...state.menu, [action.name]: action.value }};

        default:
            return state;
    }
}

export function setAlerts(name, value) {
    return {type: SET_ALERTS, name, value};
}

export function setMenu(name, value) {
    return {type: SET_MENU, name, value};
}

export default pageReducer;