import React from "react";
import {connect} from "react-redux";
import Header from "./Header";
import {logout} from "../../redux/auth-reducer";
import {setMenu} from "../../redux/page-reducer";

class HeaderContainer extends React.Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {

    }

    componentDidUpdate(){
        let props = this.props
        setTimeout(function() {
            //props.setMenu("status", "closed");
        }, 500);

        //console.log("sffdf")
    }

    render() {
        return <Header {...this.props} />;
    }
}

const mapStateToProps = state => {
    return {
        user:state.Users,
        page:state.Page,
    };
};

export default connect(
    mapStateToProps, {
        logout, setMenu
    })(HeaderContainer);