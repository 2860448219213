import React from "react";
import {connect} from "react-redux";
import Positions from "./Positions";
import {getActions, getPositions, setPositions} from "../../../../redux/positions-reducer";

class PositionsContainer extends React.Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.props.getPositions(this.props.profile.id, 1);
    }

    render() {
        return <Positions {...this.props} />;
    }
}

const mapStateToProps = state => {
    return {
        profile:state.Traders.profile,
        positions:state.Positions.list.active,
        filtered:state.Positions.list.filtered,
        loading:state.Positions.list.loading,
    };
};

export default connect(
    mapStateToProps, {
        getPositions, getActions, setPositions
    })(PositionsContainer);