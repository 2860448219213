import React from "react";
import {connect} from "react-redux";
import Notification from "./Notification";
import {
    getUserData,
    notificationUpdate,
    setOrderActions,
    setOrderDirection,
    setOrderSymbols
} from "../../../redux/user-reducer";
import {getCurrencies} from "../../../redux/options-reducer";

class NotificationContainer extends React.Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.props.getUserData();
        this.props.getCurrencies();
    }

    render() {
        return <Notification {...this.props} />;
    }
}

const mapStateToProps = state => {
    return {
        user:state.Users,
        options:state.Options,
    };
};

export default connect(
    mapStateToProps, {
        getUserData, setOrderActions, setOrderDirection, notificationUpdate, getCurrencies, setOrderSymbols
    })(NotificationContainer);