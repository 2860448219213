import React from "react";
import {connect} from "react-redux";
import Traders from "./Traders";
import {getUserData, traderNotifyOff, traderNotifyOn} from "../../../redux/user-reducer";
import {getTraders, updateTraders} from "../../../redux/traders-reducer";

class TradersContainer extends React.Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {

        this.props.getUserData();

        setTimeout(() => {
            if(this.props.user.authorized && this.props.user.traders){
                this.props.getTraders(this.props.user.traders);
            }
        }, 100)

    }

    render() {
        return <Traders {...this.props} />;
    }
}

const mapStateToProps = state => {
    return {
        user:state.Users,
        traders:state.Traders,
    };
};

export default connect(
    mapStateToProps, {
        getUserData, getTraders, updateTraders, traderNotifyOn, traderNotifyOff
    })(TradersContainer);