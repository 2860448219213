import React from 'react';
import {connect} from "react-redux";
import Billing from "./Billing";
import {getUserData} from "../../redux/user-reducer";
import {changeBilling, createInvoice, getInvoices} from "../../redux/billing-reducer";
import {setAlerts} from "../../redux/page-reducer";

class BillingContainer extends React.Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.props.getUserData();
        this.props.getInvoices();
    }

    render() {
        document.title ="Billing - Order Watcher";
        return <Billing {...this.props} />;
    }
}

const mapStateToProps = state => {
    return {
        user:state.Users,
        billing:state.Billing,
        alerts:state.Page.alerts,
    };
};

export default connect(
    mapStateToProps, {
        getUserData, getInvoices, changeBilling, createInvoice, setAlerts
    })(BillingContainer);