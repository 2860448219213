import React from "react";
import {Form, Icon, Input, Message} from "semantic-ui-react";
import moment from "moment";
import Alerts from "../Alerts/Alerts";

const Account = (props) => {

    let subscribed = <div className={'subscription not'}><span><Icon name='close' />Not Subscribed</span></div>;

    if(props.user.is_subscribed){
        let expirationPretty = moment(props.user.subscription_expiration_date).format('LL');
        subscribed = <div className={'subscription'}><Icon name='check' />Subscribed until <span>{expirationPretty}</span></div>;
    }

    return (
            <div className={'dashboard'}>
                <Alerts user={props.user} />
                <div id={"account"} className={'dashboard'}>
                    <h1>Account Data</h1>
                    <section className={"section"}>
                        {subscribed}

                        <Form className={"form auth"} onSubmit={(event) => {
                            event.preventDefault();
                            props.updateUserData();
                        }}>

                            <Form.Group widths='equal'>
                                <Form.Field
                                    control={Input}
                                    disabled={true}
                                    label='E-Mail'
                                    iconPosition='left'
                                    icon='at'
                                    value={props.user.email}
                                />
                                <Form.Field
                                    control={Input}
                                    label='Name'
                                    iconPosition='left'
                                    icon='user'
                                    placeholder='Name'
                                    value={props.user.name}
                                    type='text'
                                    minLength='2'
                                    maxLength='32'
                                    onChange={(e) => {
                                        props.setUserData('name', e.target.value);
                                    }}
                                />
                            </Form.Group>
                            <Form.Group widths='equal'>
                                <Form.Field
                                    control={Input}
                                    label='Change Password'
                                    iconPosition='left'
                                    icon='unlock alternate'
                                    placeholder='Change Password'
                                    value={props.user.password}
                                    type='password'
                                    name='password'
                                    minLength='6'
                                    maxLength='32'
                                    autoComplete="off"
                                    onChange={(e) => {
                                        props.setUserData('password', e.target.value);
                                    }}
                                />
                                <Form.Field
                                    control={Input}
                                    label='Confirm New Password'
                                    iconPosition='left'
                                    icon='unlock alternate'
                                    placeholder='Confirm New Password'
                                    value={props.user.password2}
                                    type='password'
                                    name='password2'
                                    minLength='6'
                                    maxLength='32'
                                    autoComplete="off"
                                    onChange={(e) => {
                                        props.setUserData('password2', e.target.value);
                                    }}
                                />
                            </Form.Group>
                            <Message
                                color={props.alerts.color}
                                header={props.alerts.message}
                                hidden={(!props.alerts.message)}
                            />

                            <Form.Button loading={props.user.actions.account_loading} disabled={props.user.actions.account_loading} primary floated={'right'}>Save</Form.Button>
                        </Form>
                    </section>
                </div>
            </div>
    )
};

export default Account;