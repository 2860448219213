import instance from './Api';
import {getCookie} from "../helpers";

export const PositionsApi = {

    getPositions(traderId, active){

        let config = {
            headers:{
                "auth-token": getCookie("token")
            }
        }

        return instance.post(`positions`, {
            "id": traderId,
            "active": active
        }, config)
            .then(response => {
                return response.data;
            }).catch(function (error) {
                return error.response.data;
            });
    },
    getActions(orderId){

        let config = {
            headers:{
                "auth-token": getCookie("token")
            }
        }

        return instance.post(`position/actions`, {
            "id": orderId
        }, config)
            .then(response => {
                return response.data;
            }).catch(function (error) {
                return error.response.data;
            });
    }

};