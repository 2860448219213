import {TradersApi} from "../Api/Traders";
import {getUserData} from "./user-reducer";

const UPDATE_TRADERS = "UPDATE_TRADERS";
const SET_TRADER_DATA = "SET_TRADER_DATA";
const SET_PROFILE = "SET_PROFILE";

let initialState = {
    alert: "",
    loading: false,
    list_loading: true,
    alert_color: "green",
    new_leader_url:"",
    profile: [],
    list: false,
    leaderboard: false
};

function tradersReducer(state = initialState, action) {
    switch (action.type) {

        case UPDATE_TRADERS:
            return {...state, [action.name]: action.value};

        case SET_PROFILE:
            return {...state, profile: action.value};

        case SET_TRADER_DATA:
            let newStaet = JSON.parse(JSON.stringify(state));
            newStaet.list[action.index][action.name] = action.value;
            return newStaet

        default:
            return state;
    }
}

export function setProfile(value) {
    return {type: SET_PROFILE, value};
}

export function updateTraders(name, value) {
    return {type: UPDATE_TRADERS, name, value};
}

export function setTraderData(index, name, value) {
    return {type: SET_TRADER_DATA, index, name, value};
}

export const getTraders = (request) => {
    return (dispatch) => {
        dispatch(updateTraders("list_loading", true));
        TradersApi.list(request).then(response => {
            if(response.values){
                dispatch(updateTraders("list", response.values));
            }
            dispatch(updateTraders("list_loading", false));
        });
    };
};

export const getLeaderboard = () => {
    return (dispatch) => {
        dispatch(updateTraders("list_loading", true));
        TradersApi.leaderboard().then(response => {
            if(response.values){
                dispatch(updateTraders("leaderboard", response.values));
            }
            dispatch(updateTraders("list_loading", false));
        });
    };
};

export const getProfile = (id) => {
    return (dispatch) => {
        TradersApi.profile(id).then(response => {
            if(response.values){
                if(response.values[0]){
                    dispatch(setProfile(response.values[0]));
                }
            }
        });
    };
};

export const subscribe = (encryptedUid) => {

    return (dispatch) => {

        if( !encryptedUid ){
            dispatch(updateTraders("alert_color", "orange"));
            dispatch(updateTraders("alert", "Invalid URL"));
            return false;
        }

        dispatch(updateTraders("loading", true));
        TradersApi.subscribe(encryptedUid).then(response => {
            dispatch(updateTraders("new_leader_url", ""));
            if(response.values){
                dispatch(updateTraders("alert_color", (response.values.success ? "green" : "orange")));
                if(response.values.traders){
                    dispatch(getTraders(response.values.traders));
                    dispatch(getUserData());

                }
                dispatch(updateTraders("alert", response.values.message));
            }
            dispatch(updateTraders("loading", false));
        });
    };
};

export const unsubscribe = (encryptedUid) => {

    return (dispatch) => {

        if( !encryptedUid ){
            dispatch(updateTraders("alert_color", "orange"));
            dispatch(updateTraders("alert", "Invalid URL"));
            return false;
        }

        dispatch(updateTraders("loading", true));
        TradersApi.unsubscribe(encryptedUid).then(response => {
            dispatch(updateTraders("new_leader_url", ""));
            if(response.values){
                dispatch(updateTraders("alert_color", (response.values.success ? "green" : "orange")));
                if(response.values.traders){
                    dispatch(getTraders(response.values.traders));
                    dispatch(getUserData());

                }
                dispatch(updateTraders("alert", response.values.message));
            }
            dispatch(updateTraders("loading", false));
        });
    };
};


export default tradersReducer;