import React from "react";
import {connect} from "react-redux";
import Footer from "./Footer";
import {logout} from "../../redux/auth-reducer";
import {setMenu} from "../../redux/page-reducer";

class FooterContainer extends React.Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {

    }

    render() {
        return <Footer {...this.props} />;
    }
}

const mapStateToProps = state => {
    return {
        user:state.Users,
        page:state.Page,
    };
};

export default connect(
    mapStateToProps, {
        logout, setMenu
    })(FooterContainer);