import React from "react";
import {Message} from "semantic-ui-react";
const Confirmation = (props) => {

    return (
        <div id={'confirmation'} className={"form auth section"}>
            <Message color={props.confirmation.alert_color} hidden={(!props.confirmation.alert)}>{props.confirmation.alert}</Message>
        </div>
    )
};

export default Confirmation;