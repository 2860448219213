import React from "react";
import {Icon, Message} from "semantic-ui-react";
import {NavLink} from "react-router-dom";

const Alerts = (props) => {
    return (
        <>
            <Message
                className={'custom'}
                warning
                hidden={(props.user.status !== 'trial' || !props.user.is_subscribed)}>
                <Icon name='wait' />
                <Message.Header>You are using a Trial Version Account</Message.Header>
                <p>The watchlist is <strong>limited to {props.user.watchlist_limit}</strong>. After subscribing, the limit will be <strong>increased to 20</strong>.</p>
            </Message>
            <Message
                className={'custom'}
                negative
                hidden={(props.user.is_subscribed)}>
                <Icon name='wait' />
                <Message.Header>Subscription Expired</Message.Header>
                <p>Positions tracking is suspended, you do not receive notifications in your messenger. <strong><NavLink to={"/billing"}>Subscribe</NavLink></strong> to receive information about the positions of traders again.</p>
            </Message>
            <Message
                className={'custom'}
                negative
                hidden={props.user.telegram}>
                <Icon name='telegram plane' />
                <Message.Header>You are not Subscribed to Telegram Bot</Message.Header>
                <p>To receive notifications on your phone about the positions changes of leaderboard traders, you must subscribe to our <a target={'_blank'} href="https://t.me/OrderWatcherRobot"><b>Telegram Bot</b></a>.<br/>
                    Otherwise, you will not be able to quickly receive updates on the traders positions.
                </p>
            </Message>
        </>
    )
};

export default Alerts;