import React from "react";
import {Message, Tab} from "semantic-ui-react";
import Notification from "./Notification/NotificationContainer";
import Traders from "./Traders/TradersContainer";
import Alerts from "../Alerts/Alerts";

const panes = [
    { menuItem: 'Traders Watchlist', render: () => <Traders /> },
    { menuItem: 'Notification Settings', render: () => <Notification /> },
]

const Dashboard = (props) => {
    return (
        <div id={"dashboard"} className={'dashboard'}>
            <Alerts user={props.user} />
            <Message
                onDismiss={()=>{
                    props.updateTraders("alert", "")
                }}
                color={props.traders.alert_color}
                hidden={(!props.traders.alert)}>{props.traders.alert}
            </Message>
            <Tab menu={{ secondary: true, pointing: true }} panes={panes} />
        </div>
    )
};

export default Dashboard;