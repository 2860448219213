import React, {useEffect } from 'react';
import {Button, Select, Dimmer, Icon, Loader, Message} from "semantic-ui-react";
import moment from "moment";
import {usd} from "../../helpers";
import Alerts from "../Alerts/Alerts";


let setInvoices = (props) => {

    const data = props.billing.invoices.data

    if(props.billing.invoices.loading && !data[0]){
        return (
            <Dimmer active inverted>
                <Loader inverted>Loading...</Loader>
            </Dimmer>
        )
    }

    if(!data[0]) {
        return (
            <h3>There are no invoices. To subscribe, you need to create an invoice.</h3>
        );
    }

    return (
        data.map((value, index) => {

            let time = moment(value.time).calendar();
            let network = (value.network == 'null' || !value.network ? "" : value.network.toUpperCase())
            let payer_currency = (value.payer_currency == 'null' || !value.payer_currency ? "" : value.payer_currency.toUpperCase())

            return (
                <div className={'body row'}>
                    <div className={'period'}>{props.billing.periods_names[value.period]}</div>
                    <div className={'amount'}><b>{usd.format(value.amount)}</b></div>
                    <div className={'payment'}>{value.payment_amount} {payer_currency} </div>
                    <div className={'network no-mob'}>{network}</div>
                    <div className={'time'}>{time}</div>
                    <div className={'status'}>{value.message}</div>
                    <div className={'pay'}>
                        <Button icon fluid disabled={value.is_final} className={(value.is_final ? "" : "orange")} onClick={()=>{
                            window.open(value.url,'_blank');
                        }}><Icon name='payment' /> Pay</Button>
                    </div>
                </div>
            );
        })
    )
};


const Billing = (props) => {

    useEffect(() => {
        const interval = setInterval(() => {
            props.getInvoices();
        }, 20000);

        return () => clearInterval(interval);
    }, []);


    return (
        <div id={"billing"} className={'dashboard'}>
            <Alerts user={props.user} />
            <Message
                onDismiss={()=>{
                    props.setAlerts("message", "")
                }}
                color={props.alerts.color}
                hidden={(!props.alerts.message)}>{props.alerts.message}
            </Message>

            <h1>Billing</h1>
            <Message className={'custom'} info>
                <Icon name='question circle outline' />
                <Message.Header>How to prolong a subscription?</Message.Header>
                <p>At the beginning, you need to <b>choose for what period you want</b> to renew the subscription and generate an invoice by clicking the green button <b>"Create Invoice"</b>.<br/>
                    Then, in the created invoice, <b>click the "Pay"</b> button.<br/>
                    We <b>accept payment exclusively in cryptocurrency</b> as we are crypto enthusiasts.<br/>
                    If your current subscription is still valid, then the subscription date will be incremented from the end of the current subscription date.
                </p>
            </Message>
            <section className={'plan'}>
                <Select
                    placeholder='Select your plan'
                    options={props.billing.periods}
                    value={props.billing.period}
                    onChange={(e, dropdown)=>{
                        console.log(dropdown.value)
                        props.changeBilling("period", dropdown.value)
                    }}
                />
                <Button icon disabled={props.billing.loading} loading={props.billing.loading} className={'green'} onClick={()=>{
                    props.createInvoice()
                }}><Icon name='file alternate outline' /> Create Invoice</Button>
            </section>

            <div className={'table section'}>
                <div className={'header row'}>
                    <div className={'period'}>Period</div>
                    <div className={'amount'}>USD Price</div>
                    <div className={'payment'}>Payment Amount</div>
                    <div className={'network no-mob'}>Network</div>
                    <div className={'time'}>Time</div>
                    <div className={'status'}>Status</div>
                    <div className={'pay'}></div>
                </div>
                {setInvoices(props)}
            </div>
        </div>
    )
};

export default Billing;