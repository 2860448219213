import {applyMiddleware, combineReducers, createStore} from "redux";
import thunkMiddleware from "redux-thunk";
import authReducer from "./auth-reducer";
import tradersReducer from "./traders-reducer";
import usersReducer from "./user-reducer";
import optionsReducer from "./options-reducer";
import positionsReducer from "./positions-reducer";
import pageReducer from "./page-reducer";
import billingReducer from "./billing-reducer";

let reducers = combineReducers({
    Auth:authReducer,
    Users:usersReducer,
    Traders:tradersReducer,
    Positions:positionsReducer,
    Options:optionsReducer,
    Page:pageReducer,
    Billing:billingReducer,
});

let store = createStore(reducers, applyMiddleware(thunkMiddleware));

export default store;