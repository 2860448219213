import React from "react";
import {Checkbox, Icon} from "semantic-ui-react";
import Select from "react-dropdown-select";
import {symbolsValueFormat} from "../../../helpers";
const Notification = (props) => {
    return (
        <div id={"notification"}>
            <h2><Icon name='bell' /> Notification Settings</h2>
            <div className={"section"}>
                <h3>Cryptocurrencies Settings</h3>
                <div className={'controls'}>
                    <Select
                        multi
                        placeholder="Start typing to add currencies..."
                        valueField={'symbol'}
                        labelField={'symbol'}
                        searchBy={'symbol'}
                        values={symbolsValueFormat(props.user.notification_settings.symbols)}
                        options={props.options.currencies.data}
                        onChange={(values) => {
                            props.setOrderSymbols(values)
                            props.notificationUpdate()
                        }}
                    />
                </div>
                <div className={'description'}><Icon name={'info circle'} /> Select the cryptocurrencies you want to track.</div>
            </div>
            <div className={"section"}>
                <h3>Orders Actions Settings</h3>
                <div className={'controls'}>
                    <p><Checkbox onClick={()=>{
                        props.setOrderActions("opened", !props.user.notification_settings.actions.opened)
                        props.notificationUpdate()
                    }} label={"Opened positions"} toggle checked={props.user.notification_settings.actions.opened} /></p>

                    <p><Checkbox onClick={()=>{
                        props.setOrderActions("closed", !props.user.notification_settings.actions.closed)
                        props.notificationUpdate()
                    }} label={"Closed positions"} toggle checked={props.user.notification_settings.actions.closed} /></p>

                    <p><Checkbox onClick={()=>{
                        props.setOrderActions("decreased", !props.user.notification_settings.actions.decreased)
                        props.notificationUpdate()
                    }} label={"Decreased positions"} toggle checked={props.user.notification_settings.actions.decreased} /></p>

                    <p><Checkbox onClick={()=>{
                        props.setOrderActions("increased", !props.user.notification_settings.actions.increased)
                        props.notificationUpdate()
                    }} label={"Increased positions"} toggle checked={props.user.notification_settings.actions.increased} /></p>

                    <p><Checkbox onClick={()=>{
                        props.setOrderActions("liquidated", !props.user.notification_settings.actions.liquidated)
                        props.notificationUpdate()
                    }} label={"Liquidated positions"} toggle checked={props.user.notification_settings.actions.liquidated} /></p>
                </div>
            <div className={'description'}><Icon name={'info circle'} /> Select the order actions you want to receive notifications for.</div>
            </div>
            <div className={"section"}>
                <h3>Orders Direction Settings</h3>
                <div className={'controls'}>
                    <p><Checkbox onClick={()=>{
                        props.setOrderDirection("long", !props.user.notification_settings.directions.long)
                        props.notificationUpdate()
                    }} label={"Long positions"} toggle checked={props.user.notification_settings.directions.long} /></p>

                    <p><Checkbox onClick={()=>{
                        props.setOrderDirection("short", !props.user.notification_settings.directions.short)
                        props.notificationUpdate()
                    }} label={"Short positions"} toggle checked={props.user.notification_settings.directions.short} /></p>
                </div>
                <div className={'description'}><Icon name={'info circle'} /> Managing short and long position notifications.</div>
            </div>
        </div>
    )
};

export default Notification;