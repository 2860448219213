import React from "react";
import {connect} from "react-redux";
import TraderProfile from "./TraderProfile";
import {getUserData} from "../../../redux/user-reducer";
import {getProfile, subscribe, unsubscribe} from "../../../redux/traders-reducer";
import {switchMenu} from "../../../redux/positions-reducer";
import {Dimmer, Loader, Segment} from "semantic-ui-react";

class TraderProfileContainer extends React.Component {
    constructor(props) {
        super(props);
    }

    traderID = false;

    componentDidMount() {

        let urlParams = window.location.pathname.split("/");
        this.traderID = urlParams[urlParams.length - 1];

        this.props.getUserData();
        this.props.switchMenu("active");

        if(this.traderID >= 1 && this.traderID !== this.props.profile.id){
            this.props.getProfile(this.traderID)
        }
    }

    render() {
        document.title ="Trader Profile - Order Watcher";
        return (
            this.traderID == this.props.profile.id ?
                <TraderProfile {...this.props} /> :
                <Segment className={'loading-box'}>
                    <Dimmer active inverted>
                        <Loader size='medium'>Loading</Loader>
                    </Dimmer>
                </Segment>
        )
    }
}

const mapStateToProps = state => {
    return {
        profile:state.Traders.profile,
        user:state.Users,
    };
};

export default connect(
    mapStateToProps, {
        getUserData, getProfile, subscribe, unsubscribe, switchMenu
    })(TraderProfileContainer);