import React from "react";
import {Icon} from "semantic-ui-react";
import {NavLink} from "react-router-dom";


const Footer = (props) => {
    return (
        <footer id={'footer'}>
            <div className={'midland'}>
                <div>
                    <h3>Services</h3>
                    <a target={'_blank'} href="https://t.me/OrderWatcherRobot">Telegram Bot <Icon name='telegram plane' /></a>
                    <NavLink to={"/leaderboard"}>Leaderboard</NavLink>
                    <NavLink to={"/watchlist"}>Watchlist</NavLink>
                    <NavLink to={"/billing"}>Billing</NavLink>
                </div>
                <div>
                    <h3>Exchanges</h3>
                    <a target={'_blank'} href="https://www.binance.com/en/activity/referral-entry/CPA?ref=CPA_004HMLPTXC">Binance</a>
                    <a target={'_blank'} href="https://www.bybit.com/invite?ref=MQZO6O%230">ByBit</a>
                </div>
                <div>
                    <h3>Contacts</h3>
                    <a target={'_blank'} href="https://twitter.com/Order_Watcher"><Icon name='twitter' /> Twitter</a>
                    <a target={'_blank'} href="https://t.me/sionpriorate"><Icon name='telegram plane' /> Telegram</a>
                    <a href="mailto:orderwatcher@gmail.com"><Icon name='mail outline' /> orderwatcher@gmail.com</a>
                </div>
                <div></div>
            </div>
        </footer>
    )
};

export default Footer;