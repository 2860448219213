import React from "react";
import {usd, setClassName} from "../../../helpers";

const PerformanceDays = (props) => {

    return (
        <section id={'data-overview'} className={'section'}>
            <div className={'performance'}>
                <div className={'title'}>Daily ROI</div>
                <div className={setClassName(props.profile.daily_roi, "index")}>{props.profile.daily_roi}%</div>
                <div className={'title'}>Daily PNL (USD)</div>
                <div className={setClassName(props.profile.daily_pnl, "index")}>{usd.format(props.profile.daily_pnl)}</div>
            </div>
            <div className={'performance'}>
                <div className={'title'}>Weekly ROI</div>
                <div className={setClassName(props.profile.weekly_roi, "index")}>{props.profile.weekly_roi}%</div>
                <div className={'title'}>Weekly PNL (USD)</div>
                <div className={setClassName(props.profile.weekly_pnl, "index")}>{usd.format(props.profile.weekly_pnl)}</div>
            </div>
            <div className={'performance'}>
                <div className={'title'}>Monthly ROI</div>
                <div className={setClassName(props.profile.monthly_roi, "index")}>{props.profile.monthly_roi}%</div>
                <div className={'title'}>Monthly PNL (USD)</div>
                <div className={setClassName(props.profile.monthly_pnl, "index")}>{usd.format(props.profile.monthly_pnl)}</div>
            </div>
            <div className={'performance'}>
                <div className={'title'}>Yearly ROI</div>
                <div className={setClassName(props.profile.yearly_roi, "index")}>{props.profile.yearly_roi}%</div>
                <div className={'title'}>Yearly PNL (USD)</div>
                <div className={setClassName(props.profile.yearly_pnl, "index")}>{usd.format(props.profile.yearly_pnl)}</div>
            </div>
        </section>
    )
};

export default PerformanceDays;